import { useEffect } from "react";
import Aos from "aos";
import ImageGif1 from "./assets/images/gif-1.gif";
import IconSocial1 from "./assets/images/icon-1.png";
import IconSocial2 from "./assets/images/icon-2.png";
import IconSocial3 from "./assets/images/icon-3.png";
import IconSocial4 from "./assets/images/icon-4.png";
import IconToken5 from "./assets/images/icon-5.png";
import IconToken6 from "./assets/images/icon-6.png";
import IconToken7 from "./assets/images/icon-7.png";
import ImageSection2 from "./assets/images/image-section-2.png";
import ImageToken from "./assets/images/image-token.png";
import ImageBottom from "./assets/images/image-bottom.png";

import "./App.scss";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);
  return (
    <div className="App">
      <main className="page-main">
        <section className="section-intro">
          <div data-aos="fade-up" className="container wrapper">
            <h1 className="page-title">silly cat</h1>
            <div className="img-intro">
              <img src={ImageGif1} alt="" />
            </div>
          </div>
          <ul className="socials">
            <li>
              <a href="" target="_blank" rel="noopener noreferrer">
                <img src={IconSocial1} alt="" />
              </a>
            </li>
            <li>
              <a href="" target="_blank" rel="noopener noreferrer">
                <img src={IconSocial2} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://x.com/SillyCat_SOL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconSocial3} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://t.me/SillyCat_SOL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconSocial4} alt="" />
              </a>
            </li>
          </ul>
        </section>
        <section className="section-about">
          <div data-aos="fade-up" className="container wrapper">
            <div className="left">
              <img src={ImageSection2} alt="" />
            </div>
            <div className="right">
              <h3 className="title">About silly cat</h3>
              <div className="content">
                <p>
                  Contract Address:
                  <br />
                  TBA
                </p>
                <p>
                  Welcome to Silly Cat, the best cutie cat on Solana. Get ready
                  for a purr-fectly ride!
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-tokenomics">
          <div data-aos="fade-up" className="container wrapper">
            <h3 className="title">$SIlly Tokenomics</h3>
            <div className="content">
              <div className="left">
                <div className="box-content">
                  <div className="icon-box">
                    <img src={IconToken5} alt="" />
                  </div>
                  <div className="des">
                    <p className="title">Total SUpply</p>
                    <p className="value">1 billion</p>
                  </div>
                </div>
                <div className="box-content">
                  <div className="icon-box">
                    <img src={IconToken6} alt="" />
                  </div>
                  <div className="des">
                    <p className="title">Tax</p>
                    <p className="value">0-0</p>
                  </div>
                </div>
                <div className="box-content">
                  <div className="icon-box">
                    <img src={IconToken7} alt="" />
                  </div>
                  <div className="des">
                    <p className="title">Liquidity</p>
                    <p className="value">burnt</p>
                  </div>
                </div>
              </div>
              <div className="right">
                <img src={ImageToken} alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="section-h2b">
          <div data-aos="fade-up" className="container wrapper">
            <h3 className="title">How to buy</h3>
            <div className="content">
              <div className="box-content">
                1. Set up your wallet or use trading bot on solana
              </div>
              <div className="box-content">
                2. deposit some sol to the wallet to trade
              </div>
              <div className="box-content">
                3. Ape $silly at the good price before it flies to the moon
              </div>
              <div className="box-content">
                4. join $silly community for more next updates
              </div>
            </div>
            <div className="img-bottom">
              <img src={ImageBottom} alt="" />
            </div>
          </div>
        </section>
      </main>
      <footer className="page-footer">
        <div className="container wrapper">
          <p className="copyright">Silly cat 2024</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
